.authLayout {
    background-color: #2384F6;
}

.navBar{
    background-color: #2384F6;
    height: 60px;
}

.navBar-menu{
    height: 30px;
}

.sideBar{
    width: 300px;
    flex: 0 0 auto; /* Let it grow as needed but not shrink */
    overflow-y: auto; /* Add vertical scroll if content overflows */
}

.slide-in {
    transform: translateX(0);
    transition: transform 1s ease;
}
  
.slide-out {
    transform: translateX(-100%);
    transition: transform 1s ease;
}

.right-panel {
    width: calc(100% - 300px);
    flex: 1 1 auto; /* Let it grow and shrink as needed */
    overflow-y: auto; /* Add vertical scroll if content overflows */
}

.kpi-group {
    height: 100px;
}

.kpi-img {
    height: 30px;
}

.site-img {
    height: 30px;
}

.page-content {
    height: calc(100vh - 100px);
}

.tagList {
    height: calc(100vh - 100px - 60px - 20px - 140px);
}

.chartHs {
    height: calc(100vh - 100px - 60px - 20px - 380px);
}

.dashboard-panel {
    height: 65vh;
    transition: all 0.3s ease;
}

.dashboard-logo {
    height: 80px;

}

.dashboard-panel.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white; /* Set the background color you prefer */
    z-index: 9999; /* Make sure it's on top of other content */
    overflow: auto; /* Enable scrolling if the content exceeds the screen height */
}

.custom-img {
    height: 50vh;
}

.dashboard-chart {
    height: 34vh;
    width: 95%;
    margin-left: 20px;
}

.img-widget {
    position: relative;
    text-align: center;
    color: white;
}

.widget-1 {
    position: absolute;
    top: 100px;
    left: 15%;
    padding: 10px;
    background: rgba(0,0,255,0.5);
}

.widget-2 {
    position: absolute;
    top: 100px;
    left: 35%;
    padding: 10px;
    background: rgba(0,0,255,0.5);
}

.widget-3 {
    position: absolute;
    top: 100px;
    left: 55%;
    padding: 10px;
    background: rgba(0,0,255,0.5);
}

.widget-4 {
    position: absolute;
    top: 100px;
    left: 75%;
    padding: 10px;
    background: rgba(0,0,255,0.5);
}

.widget-5 {
    position: absolute;
    top: 250px;
    left: 35%;
    padding: 10px;
    background: rgba(0,0,255,0.5);
}

.widget-6 {
    position: absolute;
    top: 250px;
    left: 75%;
    padding: 10px;
    background: rgba(0,0,255,0.5);
}

.widget-10 {
    position: absolute;
    top: 200px;
    left: 25%;
    padding: 10px;
    background: rgba(0,0,255,0.5);
}

.widget-11 {
    position: absolute;
    top: 200px;
    left: 65%;
    padding: 10px;
    background: rgba(0,0,255,0.5);
}

.widget-value {
    font-size: 32;
    font-weight: bold;
}

@media (max-width: 768px) {
    /* For screens with a minimum width of 768px (typical tablets and above) */
    .custom-img {
        width: 100%; /* You can adjust this value as needed */
        height: auto;
    }

    .widget-1 {
        position: absolute;
        top: 50px;
        left: 15%;
        padding: 5px;
        font-size: 10px;
        background: rgba(0,0,255,0.5);
    }
    
    .widget-2 {
        position: absolute;
        top: 50px;
        left: 35%;
        padding: 5px;
        font-size: 10px;
        background: rgba(0,0,255,0.5);
    }
    
    .widget-3 {
        position: absolute;
        top: 50px;
        left: 55%;
        padding: 5px;
        font-size: 10px;
        background: rgba(0,0,255,0.5);
    }
    
    .widget-4 {
        position: absolute;
        top: 50px;
        left: 75%;
        padding: 5px;
        font-size: 10px;
        background: rgba(0,0,255,0.5);
    }
    
    .widget-5 {
        position: absolute;
        top: 120px;
        left: 35%;
        padding: 5px;
        font-size: 10px;
        background: rgba(0,0,255,0.5);
    }
    
    .widget-6 {
        position: absolute;
        top: 120px;
        left: 70%;
        padding: 5px;
        font-size: 10px;
        background: rgba(0,0,255,0.5);
    }
    
    .widget-10 {
        position: absolute;
        top: 100px;
        left: 25%;
        padding: 5px;
        font-size: 10px;
        background: rgba(0,0,255,0.5);
    }
    
    .widget-11 {
        position: absolute;
        top: 100px;
        left: 65%;
        padding: 5px;
        font-size: 10px;
        background: rgba(0,0,255,0.5);
    }
    
    .widget-value {
        font-size: 18;
        font-weight: bold;
    }
}